.App {
    text-align: center;
}
.container-center {
    text-align:center;
}
.center-login {
    padding-top: 20px;
    display: inline-block;
    text-align:left;
}
.center {
    padding-top: 20px;
    display: inline-block;
    text-align:left;
    width: 100%;
    max-width: 1000px;
    background-color: white;
}
.container {
    display: inline-block;
    text-align:left;
    width: 100%;
}
.center-vertically {
    align-items: center;
    display: flex;
}
.list {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bigInput > div > * {
    font-weight: bolder !important;
    /* font-size: larger !important; */
}

.bigInput > label {
    font-weight: bolder !important;
    /* font-size: larger !important; */
}

#main-content {
    background-color: #fafafa;
    position: relative;
}