body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* background-color: #eae9ef; */
  background-color: #FAFAFA;
}
.print{ display: none !important; }
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  .noPrint { display: none !important; }
  .print { display:block !important; }

  .content-block, p {
    page-break-inside: avoid;
  }

  html, body {
    /* height: 297mm; */
    background-color: white;
  }
  #container-center {
    margin-top: 10px;
  }
  .tablePrint {
    border: 1px solid black;
    border-collapse: collapse;
  }
  .tablePrint th, .tablePrint td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

@page {
  size: A4;
  margin: 5mm 10mm 5mm 10mm;
}

@media print {
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}